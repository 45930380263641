<template>
  <placement-view-provider
    :placement-name="'banner-widget'"
    :placement-type="getPlacementObjectType"
  >
    <div class="placement-banner-widget">
      <div
        v-for="(banner, key) in banners"
        :key="key"
        class="banner-block-item mb-1"
      >
        <v-banner-block-item
          :banner="banner"
          :position="key"
          :media="'test'"
        />
      </div>
    </div>
  </placement-view-provider>
</template>

<script>
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import PlacementBannerWidgetLoader from '@nsf/product-relationship-loader-placement/loaders/banners/placementBannerWidgetLoader.js'
import PlacementViewProvider from '@nsf/gtm/providers/PlacementViewProvider.js'
import { getBannerPlacementType } from '@nsf/gtm/utils/banner.js'
import { mapGetters } from 'vuex'

const {
  public: { placementBannersEnabled },
} = useRuntimeConfig()

export default {
  components: {
    PlacementViewProvider,
    'v-banner-block-item': () => import('@nsf/base/components/PageBuilder/BannerBlocks/Item.vue'),
  },

  inject: ['WindowDimensionProvider'],

  props: {
    data: {
      type: Object,
      default: null,
    },
  },

  data() {
    return { banners: [] }
  },

  computed: {
    pageType() {
      switch (this.entityType) {
        case 'catalog_product':
          return 'productDetail'
        case 'cms_post':
        default:
          return 'homepage'
      }
    },
    getPlacementObjectType() {
      return getBannerPlacementType()
    },
  },

  methods: {
    ...mapGetters({ entityType: '_base/pagebuilder/entityType' }),
  },

  async fetch() {
    if (placementBannersEnabled) {
      const placementDataLoader = PlacementBannerWidgetLoader.bind({ ctx: this })
      const position = this.data?.children?.[0]?.position || 'banner-widget'
      const placementData = await placementDataLoader(this.pageType, position)
      if (placementData.banners.length > 0) {
        this.banners = placementData.banners.map((item) => ({
          content: item.content,
          opts: [
            {
              path: item.imageMobileUrl,
              w: 360,
              h: 150,
              media: this.WindowDimensionProvider.forMobile,
            },
            {
              path: item.imageDesktopUrl,
              w: 1140,
              h: 150,
            },
          ],
          urlLink: item.url,
        }))
      }
    }
  },
}
</script>
