import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import carrierCodes from './carrierCodes.mjs'
import config from '../index.mjs'
import features from './features.mjs'
import termsPages from './termsPages.mjs'
import paymentCodes from './paymentCodes.mjs'
import marketplace from './marketplace.mjs'
import inputTypes from './inputTypes.mjs'

export default defineAppConfig({
  ...carrierCodes,
  ...features,
  ...termsPages,
  ...paymentCodes,
  ...marketplace,
  ...inputTypes,
  agreementsFilterForPlaceOrder: ({ agreements = [] } = {}) => agreements.filter(({ channel }) => channel !== 'PPD'),
  boxShortDescription: ({ pickupPlaceAddress }) => pickupPlaceAddress?.street,
  companyDetailFieldsOrder: {
    crn: 1,
    vatId: 4,
  },
  companyValidationExceptions: [
    'companyRegistrationNumber',
    'vatId',
  ],
  defaultAgreements: (agreementsTopic) => [
    {
      channel: 'PRICE_COMPARISONS',
      topic: agreementsTopic,
      value: 'N',
    },
  ],
  firstPartySellerName: 'Dr. Max',
  paymentFailedMessageComponent: 'general',
  restrictedShippingCarriers: ['drmaxpickupplace~drmax_box'], // either carrierCode (e.g. drmaxpacketa) or both carrierCode and methodCode, e.g. (drmaxpickupplace~drmax_box)
  shippingInfoBlocks: '',
  stockStatusChunkSize: 50,
  thankYouText: {
    payment: 'SMS',
    dispatch: 'email',
  },
  zipRestrictedShippingCarriers: [], // either carrierCode (e.g. drmaxpacketa) or both carrierCode and methodCode, e.g. (drmaxpickupplace~drmax_box)
  ignorePickupPlacesFromMagento: ['drmaxclickandcollectshipping'], // list of carrier codes for with we ignore magento's drmaxPickupIds
  ...config,
})
