import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { getPlacementBanners } from '@nsf/product-relationship-loader-placement/repositories/PlacementRepository.js'

const {
  productRelationshipLoaderPlacement: {
    placementBannerWidgetLimit,
  },
} = useAppConfig()

export default async function (pageType = null, placement = null) {
  const userId = ''
  const config = this?.config ?? {
    limit: placementBannerWidgetLimit,
    pageType,
  }
  return await getPlacementBanners(placement, userId, config, this?.ctx)
}
